<template>
    <Button
        label="Nova Conta"
        icon="pi pi-plus"
        @click="toInserir()"
        v-if="$temAcessoView('PAG-CONTAS-01')"
    />
</template>

<script>
export default {
    methods: {
        toInserir() {
            this.$router.push({
                name: 'Pagamentos_Contas_Inserir',
            });
        },
    },
};
</script>
